<template>
    <TableHeader :has-selected-rows="false" :has-active-filters="hasActiveFilters" @clear-all-filters="clearAllFilters">
        <template #filterSection>
            <div class="filters flex gap-2">
                <SortDataView
                    :order-by-prop="videoFilters.orderBy"
                    :sort-order-prop="videoFilters.sortOrder"
                    @on-sort-filter="onSort"
                ></SortDataView>
                <DataFilterChannels
                    :channels="videoFilters.includeChannels"
                    @update-channel-filter="onUpdateChannelFilter"
                    @remove-channel-filter="onRemoveChannelFilter"
                ></DataFilterChannels>
                <DataFilterType
                    :types="videoFilters.includeTypes"
                    @update-type-filter="onUpdateTypeFilter"
                    @remove-type-filter="onRemoveTypeFilter"
                ></DataFilterType>
                <DataFilterFeature
                    :features="videoFilters.includeFeatures"
                    @update-feature-filter="onUpdateFeatureFilter"
                    @remove-feature-filter="onRemoveFeatureFilter"
                ></DataFilterFeature>
            </div>
        </template>

        <template #right>
            <div class="flex items-center gap-2">
                <IconField>
                    <InputIcon class="far fa-search" />
                    <InputText v-model="searchQueryInput" size="small" placeholder="Search" class="pl-10" />
                </IconField>
            </div>
        </template>
    </TableHeader>
</template>

<script lang="ts" setup>
import { useHelpCenterStore } from '@/stores/helpCenter'
import { useDebounceFn } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import SortDataView from '../SortDataView.vue'
import DataFilterChannels from '@/components/dataView/dataFilter/filters/DataFilterChannels.vue'
import DataFilterType from '@/components/dataView/dataFilter/filters/DataFilterType.vue'
import {
    HelpCenterOrderByOptions,
    type HelpCenterChannel,
    type HelpCenterFeature,
    type HelpCenterType
} from '@/interfaces/HelpCenter'
import DataFilterFeature from '@/components/dataView/dataFilter/filters/DataFilterFeature.vue'
import type { SortOrderNumber } from '@/interfaces/Sorting'
import { isValueInEnum } from '@/utils/helpers'

const helpCenterStore = useHelpCenterStore()
const { videoFilters, searchValue } = storeToRefs(helpCenterStore)

const searchQueryInput = ref()

const debouncedSearch = useDebounceFn(
    () => {
        helpCenterStore.updateVideoFilters({ search: searchQueryInput.value })
    },
    400,
    { maxWait: 3000 }
)

watch(searchQueryInput, () => {
    debouncedSearch()
})

watch(
    searchValue,
    () => {
        searchQueryInput.value = searchValue.value
    },
    { immediate: true }
)

const onSort = async (sortEvent: { orderBy: HelpCenterOrderByOptions; sortOrder: SortOrderNumber }): Promise<void> => {
    const orderBy = isValueInEnum(sortEvent.orderBy, HelpCenterOrderByOptions)
        ? (sortEvent.orderBy as HelpCenterOrderByOptions)
        : undefined

    if (orderBy) {
        helpCenterStore.updateVideoFilters({
            orderBy,
            sortOrder: sortEvent.sortOrder,
            page: 1,
            first: 0
        })
    } else {
        helpCenterStore.removeVideoFilters(['orderBy', 'sortOrder'])
    }
}

const onUpdateChannelFilter = (filter: { channels: HelpCenterChannel[] }): void => {
    helpCenterStore.updateVideoFilters({
        includeChannels: filter.channels
    })
}

const onUpdateTypeFilter = (filter: { types: HelpCenterType[] }): void => {
    helpCenterStore.updateVideoFilters({
        includeTypes: filter.types
    })
}

const onUpdateFeatureFilter = (filter: { features: HelpCenterFeature[] }): void => {
    helpCenterStore.updateVideoFilters({
        includeFeatures: filter.features
    })
}

const onRemoveChannelFilter = (): void => {
    helpCenterStore.removeVideoFilters(['includeChannels'])
}

const onRemoveTypeFilter = (): void => {
    helpCenterStore.removeVideoFilters(['includeTypes'])
}

const onRemoveFeatureFilter = (): void => {
    helpCenterStore.removeVideoFilters(['includeFeatures'])
}

function clearAllFilters(): void {
    helpCenterStore.removeVideoFilters(['includeChannels', 'includeFeatures', 'includeTypes', 'search'])
}

const hasActiveFilters = computed(() => {
    return videoFilters.value.includeChannels || videoFilters.value.includeFeatures || videoFilters.value.includeTypes
        ? true
        : false
})
</script>
