<template>
    <TableHeader
        title="User Management"
        :has-selected-rows="false"
        :has-active-filters="hasActiveFilters"
        full-page
        @clear-all-filters="clearAllFilters"
    >
        <template #filterSection>
            <div class="filters flex gap-2">
                <FilterUserRole
                    :user-roles="userManagementFilters.role"
                    :users-summary="state.usersSummary"
                    @get-users-summary-info="onGetUsersSummaryInfo"
                    @update-user-role-filter="onUpdateUserRoleFilter"
                    @remove-user-role-filter="onRemoveUserRoleFilter"
                />
                <FilterUserStatus
                    :active="userManagementFilters.active"
                    :inactive="userManagementFilters.inactive"
                    @update-user-active-filter="onUpdateUserStatusFilter"
                    @remove-user-active-filter="onRemoveUserStatusFilter"
                />
                <FilterDeactivatedAt
                    :deactivated-at-before="userManagementFilters.deactivatedAtBefore"
                    :deactivated-at-after="userManagementFilters.deactivatedAtAfter"
                    @update-deactivated-at-filter="onUpdateDeactivatedAtFilter"
                    @remove-deactivated-at-filter="onRemoveDeactivatedAtFilter"
                />
                <FilterLastLogin
                    :last-login-before="userManagementFilters.lastLoginBefore"
                    :last-login-after="userManagementFilters.lastLoginAfter"
                    @update-last-login-filter="onUpdateLastLoginFilter"
                    @remove-last-login-filter="onRemoveLastLoginFilter"
                />
                <FilterCreatedAt
                    :created-at-before="userManagementFilters.createdAtBefore"
                    :created-at-after="userManagementFilters.createdAtAfter"
                    @update-created-at-filter="onUpdateCreatedAtFilter"
                    @remove-created-at-filter="onRemoveCreatedAtFilter"
                />
            </div>
        </template>

        <template #right>
            <div class="flex items-center gap-2 mr-2">
                <IconField class="flex-grow">
                    <InputIcon class="far fa-search" />
                    <InputText
                        v-model="searchQueryInput"
                        size="small"
                        placeholder="Search user"
                        class="pl-10 pr-10 py-2 border rounded-lg w-full"
                    />
                    <!-- Clear 'X' button for search -->
                    <button
                        v-if="searchQueryInput.length > 0"
                        class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        @click="clearSearch"
                    >
                        <FaIcon icon="far fa-xmark" />
                    </button>
                </IconField>
            </div>
            <!-- Export button -->
            <Button
                type="button"
                size="small"
                label="Export"
                icon="far fa-file-export"
                :loading="isExportLoading"
                rounded
                :pt="{
                    root: {
                        class: [
                            'bg-primary-200 !text-primary-500 border-none focus:ring-0 hover:!bg-primary-300 focus:!bg-primary-300'
                        ]
                    },
                    icon: { class: ['!mr-0'] }
                }"
                @click="toggle"
            />
            <Popover ref="op" class="bg-gray-100 rounded-2xl shadow border border-surface-950/10 after:content-none">
                <div class="flex flex-col">
                    <div class="text-surface-950 text-sm font-normal leading-tight mb-3">Name Export</div>
                    <InputText id="usersExportName" v-model="usersExportName" class="pl-10 text-sm" />

                    <div class="flex justify-end mt-3">
                        <div class="flex gap-2">
                            <Button type="button" size="small" label="Cancel" rounded text @click="toggle" />
                            <Button type="button" size="small" label="Create" rounded @click="submitExport" />
                        </div>
                    </div>
                </div>
            </Popover>
        </template>
    </TableHeader>
</template>

<script setup lang="ts">
import { watch, computed, ref, onUnmounted } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import FilterCreatedAt from '@/components/dataTable/tableFilter/filters/FilterCreatedAt.vue'
import FilterLastLogin from '@/components/dataTable/tableFilter/filters/FilterLastLogin.vue'
import FilterDeactivatedAt from '@/components/dataTable/tableFilter/filters/FilterDeactivatedAt.vue'
import FilterUserRole from '@/components/dataTable/tableFilter/filters/FilterUserRole.vue'
import FilterUserStatus from '@/components/dataTable/tableFilter/filters/FilterUserStatus.vue'
import { storeToRefs } from 'pinia'
import TableHeader from '@/components/dataTable/TableHeader.vue'
import { useUserManagementStore } from '@/stores/useUserManagementStore'
import type { UserRoles } from '@/constants'

defineProps<{
    isExportLoading: boolean
}>()

const emit = defineEmits(['get-users-summary-info', 'export-users'])
const op = ref()
const usersExportName = ref<string>('users_export')

const toggle = (event: Event): void => {
    op.value.toggle(event)
}

const submitExport = (): void => {
    emit('export-users', usersExportName.value)
    op.value.toggle()
}

const userManagementStore = useUserManagementStore()
const { userManagementFilters, state, searchValue } = storeToRefs(userManagementStore)

const searchQueryInput = ref<string>('')

const debounceSearch = useDebounceFn(
    (): void => {
        userManagementStore.updateUserFilters({ search: searchQueryInput.value })
    },
    400,
    { maxWait: 3000 }
)

watch(searchQueryInput, (): void => {
    debounceSearch()
})

watch(
    searchValue,
    (): void => {
        searchQueryInput.value = searchValue.value
    },
    { immediate: true }
)

onUnmounted((): void => {
    userManagementStore.clearAllUserFilters()
})

const clearSearch = (): void => {
    userManagementStore.removeUserFilters(['search'])
}

const onUpdateCreatedAtFilter = (filter: { createdAtBefore: string; createdAtAfter: string }): void => {
    userManagementStore.updateUserFilters(filter)
}

const onRemoveCreatedAtFilter = (): void => {
    userManagementStore.removeUserFilters(['createdAtBefore', 'createdAtAfter'])
}

const onUpdateLastLoginFilter = (filter: { lastLoginBefore: string; lastLoginAfter: string }): void=> {
    userManagementStore.updateUserFilters(filter)
}

const onRemoveLastLoginFilter = (): void => {
    userManagementStore.removeUserFilters(['createdAtBefore', 'createdAtAfter'])
}

const onUpdateDeactivatedAtFilter = (filter: { deactivatedAtBefore: string; deactivatedAtAfter: string }): void => {
    userManagementStore.updateUserFilters(filter)
}

const onRemoveDeactivatedAtFilter = (): void => {
    userManagementStore.removeUserFilters(['deactivatedAtBefore', 'deactivatedAtAfter'])
}

const onUpdateUserRoleFilter = (filter: { role: UserRoles[] }): void => {
    userManagementStore.updateUserFilters(filter)
}

const onRemoveUserRoleFilter = (): void => {
    userManagementStore.removeUserFilters(['role'])
}

const onUpdateUserStatusFilter = (filter: { active: boolean; inactive: boolean }): void => {
    userManagementStore.updateUserFilters(filter)
}

const onRemoveUserStatusFilter = (): void => {
    userManagementStore.removeUserFilters(['active', 'inactive'])
}

const onGetUsersSummaryInfo = (): void => {
    emit('get-users-summary-info')
}

const hasActiveFilters = computed(() => {
    return userManagementFilters.value.active ||
        userManagementFilters.value.inactive ||
        userManagementFilters.value.createdAtBefore ||
        userManagementFilters.value.createdAtAfter ||
        userManagementFilters.value.deactivatedAtBefore ||
        userManagementFilters.value.deactivatedAtAfter ||
        userManagementFilters.value.lastLoginBefore ||
        userManagementFilters.value.lastLoginAfter ||
        userManagementFilters.value.role ||
        userManagementFilters.value.search
        ? true
        : false
})

const clearAllFilters = (): void => {
    userManagementStore.clearAllUserFilters()
}
</script>
