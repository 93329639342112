import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import helpCenterApi from '@/api/helpCenter.api'
import {
    HelpCenterOrderByOptions,
    type GetHelpCenterUIParams,
    type PostHelpCenterUIPayload,
    type PutHelpCenterUIPayload,
    type HelpCenterVideo
} from '@/interfaces/HelpCenter'

import { sortOrderHelpers } from '@/interfaces/Sorting'
import type {
    GetHelpCenterApiParams,
    GetHelpCenterVideosApiResponse,
    PostHelpCenterApiBody,
    PostHelpCenterApiResponse,
    PutHelpCenterApiBody,
    PutHelpCenterApiResponse,
    HelpCenterApiItem
} from '@/api/interfaces/helpCenter.types'
import type { PageInfo } from '@/interfaces/PageInfo'

const STORAGE_KEY = 'help-center-recent-searches'
const MAX_RECENT_SEARCHES = 8

interface HelpCenterState {
    recentSearches: string[]
    helpCenterVideos: HelpCenterVideo[]
    pageInfo: PageInfo | null
    showVideoFormDialog: boolean
    editVideoData: HelpCenterVideo | null
    error: string | null
}

function getStoredSearches(): string[] {
    try {
        const stored = localStorage.getItem(STORAGE_KEY)
        return stored ? JSON.parse(stored) : []
    } catch (error) {
        console.error('Failed to parse stored searches:', error)
        return []
    }
}

const storeSearches = (searches: string[]): void => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(searches))
    } catch (error) {
        console.error('Failed to store searches:', error)
    }
}

interface FetchVideosOptions {
    storeResults?: boolean
    filters?: GetHelpCenterUIParams
}

export const useHelpCenterStore = defineStore('helpCenter', () => {
    const state = ref<HelpCenterState>({
        recentSearches: getStoredSearches(),
        helpCenterVideos: [],
        pageInfo: null,
        error: null,
        showVideoFormDialog: false,
        editVideoData: null
    })

    const videoFilters = ref<GetHelpCenterUIParams>({
        size: 100,
        page: 1,
        orderBy: HelpCenterOrderByOptions.PublishTimeAsc,
        sortOrder: -1,
        first: 0
    })

    const currentPage = computed(() => videoFilters.value.page || 1)
    const currentFirst = computed(() => (currentPage.value - 1) * (videoFilters.value.size || 100))

    watch(currentPage, () => {
        videoFilters.value.first = currentFirst.value
    })

    async function fetchVideos(
        options: FetchVideosOptions = { storeResults: true }
    ): Promise<HelpCenterVideo[] | undefined> {
        const filters = options.filters ? options.filters : videoFilters.value

        try {
            state.value.error = null

            const apiParams = transformFiltersToApiParams(filters)
            const response: GetHelpCenterVideosApiResponse = await helpCenterApi.getVideos(apiParams)

            if (options.storeResults) {
                state.value.helpCenterVideos = response.data as HelpCenterVideo[]
                state.value.pageInfo = response.pageInfo || null
            }

            return response.data as HelpCenterVideo[]
        } catch (error) {
            handleFetchError(error)
        }
    }

    function transformFiltersToApiParams(filters: GetHelpCenterUIParams): GetHelpCenterApiParams {
        const { orderBy, sortOrder, ...rest } = filters
        return {
            ...rest,
            orderBy: orderBy && sortOrder ? sortOrderHelpers.applyToOrderBy(orderBy, sortOrder) : orderBy,
            includeChannels: filters.includeChannels?.join(','),
            includeFeatures: filters.includeFeatures?.join(','),
            includeTypes: filters.includeTypes?.join(',')
        }
    }

    function storeSearchInHistory(search?: string) {
        if (!search?.trim()) return
        const searches = new Set([search, ...state.value.recentSearches])
        state.value.recentSearches = Array.from(searches).slice(0, MAX_RECENT_SEARCHES)
        storeSearches(state.value.recentSearches)
    }

    function handleFetchError(error: unknown) {
        state.value.error = 'Failed to fetch videos'
        console.error('Fetch error:', error)
        throw error
    }

    function updateVideoFilters(params: Partial<GetHelpCenterUIParams>): void {
        videoFilters.value = {
            ...videoFilters.value,
            ...params
        }
    }

    function removeVideoFilters<T extends keyof GetHelpCenterUIParams>(keysToClear: T[]): void {
        keysToClear.forEach((key) => {
            if (key in videoFilters.value) {
                videoFilters.value[key] = undefined as GetHelpCenterUIParams[T]
            }
        })
    }

    const filteredSuggestions = computed(() => {
        return state.value.recentSearches.slice(0, MAX_RECENT_SEARCHES)
    })

    const searchValue = computed(() => {
        return videoFilters.value.search || ''
    })

    function openVideoFormDialog(editData: HelpCenterVideo | null = null) {
        state.value.showVideoFormDialog = true
        state.value.editVideoData = editData
    }

    function closeVideoFormDialog() {
        state.value.showVideoFormDialog = false
        state.value.editVideoData = null
    }

    async function createVideo(params: PostHelpCenterUIPayload): Promise<HelpCenterApiItem> {
        try {
            state.value.error = null

            const apiBody: PostHelpCenterApiBody = {
                ...params,
                channels: params.channels.map((channel) => channel.toString()),
                features: params.features.map((feature) => feature.toString()),
                type: params.type.toString()
            }

            const response: PostHelpCenterApiResponse = await helpCenterApi.createVideo(apiBody)
            return response.data
        } catch (error) {
            state.value.error = 'Failed to create video'
            console.error('Create error:', error)
            throw error
        }
    }

    async function updateVideo(id: string, params: PutHelpCenterUIPayload): Promise<HelpCenterApiItem> {
        try {
            state.value.error = null

            const apiBody: PutHelpCenterApiBody = {
                ...params,
                channels: params.channels?.map((channel) => channel.toString()),
                features: params.features?.map((feature) => feature.toString()),
                type: params.type.toString()
            }

            const response: PutHelpCenterApiResponse = await helpCenterApi.updateVideo(id, apiBody)
            return response.data
        } catch (error) {
            state.value.error = 'Failed to update video'
            console.error('Update error:', error)
            throw error
        }
    }

    function removeSearchFromHistory(search: string) {
        state.value.recentSearches = state.value.recentSearches.filter((item) => item !== search)
        storeSearches(state.value.recentSearches)
    }

    async function deleteVideo(id: string) {
        await helpCenterApi.deleteVideo(id)
        state.value.helpCenterVideos = state.value.helpCenterVideos.filter((video) => video.id !== id)
    }

    async function submitSupportRequest(data: {
        emails: string[]
        subject: string
        description: string
    }): Promise<void> {
        try {
            await helpCenterApi.submitRequest({
                emails: data.emails.join(', '),
                subject: data.subject,
                description: data.description
            })
        } catch (error) {
            console.error('Support request error:', error)
            throw error
        }
    }

    return {
        state,
        videoFilters,
        searchValue,
        currentPage,
        currentFirst,
        fetchVideos,
        updateVideoFilters,
        removeVideoFilters,
        filteredSuggestions,
        openVideoFormDialog,
        closeVideoFormDialog,
        createVideo,
        updateVideo,
        storeSearchInHistory,
        removeSearchFromHistory,
        deleteVideo,
        submitSupportRequest
    }
})
