<template>
    <Toast></Toast>
    <HelpCenterHeader />
    <router-view />
    <VideoFormDialog
        v-model:visible="helpCenterStore.state.showVideoFormDialog"
        :is-edit="!!helpCenterStore.state.editVideoData"
        :video-data="helpCenterStore.state.editVideoData"
        @update:visible="handleVisibilityChange"
        @refetch="handleRefetch"
    />
</template>

<script lang="ts" setup>
import { useHelpCenterStore } from '@/stores/useHelpCenterStore'
import HelpCenterHeader from '../components/HelpCenterHeader.vue'
import VideoFormDialog from '../components/VideoFormDialog.vue'

const helpCenterStore = useHelpCenterStore()

const handleVisibilityChange = (visible: boolean): void => {
    if (!visible) {
        helpCenterStore.closeVideoFormDialog()
    }
}

const handleRefetch = (): void => {
    helpCenterStore.fetchVideos()
}
</script>
