import { defineStore } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import authApi, { type MasqueradeUser } from '@/api/auth.api'
import type { UserBrandsResponse, UserBrandData, UserData, UserDataResponse, FeatureFlags } from '@/api/auth.api'
import { HelpCenterChannel } from '@/interfaces/HelpCenter'

export const useUserStore = defineStore('user', () => {
    const user = ref<UserData | null>(null)
    const rootUser = ref<string | undefined>()
    const masqueradeRoles = ref<MasqueradeUser[]>([])
    const userBrands = ref<UserBrandData[]>([])
    const isAuthenticated = ref(false)
    const loading = ref(false)
    const route = useRoute()
    const currentMasqueradeUser = ref<string | undefined>()
    const featureFlags = ref<FeatureFlags>()

    const isSuperUser = computed(() => {
        return user.value?.activeRole === 'superuser'
    })

    onMounted(async () => {
        if (!user.value && route.meta.requiresAuth) {
            await fetchUser()
        }
        if (userBrands.value.length === 0) {
            await fetchUserBrands()
        }

        await getFeatureFlags()
    })

    const setUser = (userData: UserData | null) => {
        user.value = userData
        isAuthenticated.value = !!userData
    }

    const loginUser = async (username: string, password: string) => {
        loading.value = true
        try {
            const response = await authApi.login(username, password)
            if (response.success) {
                await fetchUser()
            }
            return response
        } catch (error) {
            console.error('Login error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const logoutUser = async () => {
        loading.value = true
        try {
            await authApi.logout()
            setUser(null)
        } catch (error) {
            console.error('Logout error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const forgotPassword = async (email: string) => {
        loading.value = true
        try {
            await authApi.forgotPassword(email)
        } catch (error) {
            console.error('Password reset error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const fetchUser = async () => {
        loading.value = true
        try {
            const userData: UserDataResponse = await authApi.fetchUserData()
            setUser(userData.data)
        } catch (error) {
            console.error('Fetch user error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const resetPassword = async (newPassword: string, token: string) => {
        loading.value = true
        try {
            await authApi.resetPassword(newPassword, token)
        } catch (error) {
            console.error('Password change error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const fetchUserBrands = async (): Promise<void> => {
        if (user.value && user.value.lastActiveCompanyId) {
            try {
                const brandsData: UserBrandsResponse = await authApi.fetchUserBrandsData(user.value?.lastActiveCompanyId)
                userBrands.value = brandsData.data
            } catch (error) {
                console.error('Fetch user brands error:', error)
                throw error
            }
        }
    }

    const handleMasqueradeInit = async (): Promise<void> => {
        await fetchRootUser()
        if (rootUser.value) {
            await fetchAvailableMasqueradeRoles(rootUser.value)
        }
    }

    const fetchRootUser = async (): Promise<void> => {
        try {
            const resp = await authApi.fetchRootUserData()
            if (resp.original_username) {
                rootUser.value =  resp.original_username
            } else {
                rootUser.value = user.value?.username
            }
        } catch (error) {
            console.error('Get root user user error: ', error)
        }
    }

    const fetchAvailableMasqueradeRoles = async (rootUser: string): Promise<void> => {
        try {
            const resp = await authApi.fetchMasqueradeRoleData(rootUser)
            if (resp.masquerade_users.length > 0) {
                masqueradeRoles.value = [...resp.masquerade_users, { admin_user: rootUser, masquerade_user: rootUser} ]; // root user must be included in this list
                currentMasqueradeUser.value = resp.current_user
            }
        } catch (error) {
            console.error('Get masquerade roles error: ', error)
        }
    }

    const setMasqueradeUser = async (username: string): Promise<void> => {
        try {
            await authApi.setMasqueradeUser(username);
            location.href = location.origin
        } catch (error) {
            console.error('Set masquerade user error: ', error)
        }
    }

    const getFeatureFlags = async (): Promise<any> => {
        try {
            const resp = await authApi.getFeatureFlags()
            featureFlags.value = resp
        } catch (error) {
            console.error('Get feature flags failed. Error : ', error)
        }
    }

    const activeBrand = computed<UserBrandData | undefined>(() => {
        return userBrands.value.find((brand: UserBrandData) => brand.id === user.value?.lastActiveBrandId) || userBrands.value[0]
    })

    const activeChannel = computed<HelpCenterChannel | undefined>(() => {
        return activeBrand.value?.channel
    })

    watch(activeBrand, (newVal) => {
        if (!newVal) {
            if (userBrands.value.length > 0) {
                // if no match found, switch to the first available brand in the list of brands
                window.location.href = `/accounts/v1/me/activate-group/?group=${userBrands.value[0].id}&next=/pages/browse/list&errnext=/login`
            } else {
                window.location.href = '/login/'
            }
        } 
    })

    return {
        user,
        rootUser,
        masqueradeRoles,
        userBrands,
        isAuthenticated,
        loading,
        activeBrand,
        activeChannel,
        loginUser,
        logoutUser,
        forgotPassword,
        fetchUser,
        fetchUserBrands,
        resetPassword,
        setMasqueradeUser,
        getFeatureFlags,
        handleMasqueradeInit,
        featureFlags,
        currentMasqueradeUser,
        isSuperUser
    }
})
