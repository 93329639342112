<template>
    <Toast></Toast>
    <ConfirmDialog
        group="confirm-tags-delete"
        :pt="{
            root: { class: ['min-w-[400px]'] }
        }"
    >
    </ConfirmDialog>
    <ConfirmPopup
        id="tags-confirm-delete-tag"
        group="confirm-tag-delete"
        :pt="{
            root: { class: ['min-w-[400px]'] }
        }"
        @click.stop
    ></ConfirmPopup>
    <DataTable
        v-model:selection="selectedTags"
        :value="tagSummaries"
        selection-mode="multiple"
        :rows="tableState.size"
        data-key="tag.id"
        table-style="width: 100%"
        scrollable
        resizable-columns
        column-resize-mode="fit"
        sort-mode="single"
        :sort-field="tableState.orderBy"
        :sort-order="tableState.sortOrder"
        :removable-sort="true"
        lazy
        :loading="loading"
        :row-class="rowClass"
        class="tags-list-table"
        :pt="{
            root: { class: ['text-sm relative flex flex-col tags-table-root'] },
            tableContainer: { class: ['flex-1'] },
            header: { class: ['text-xl !p-0 !border-b-0'] },
            wrapper: { class: ['flex-1'] },
            column: {
                headerCell: { class: ['border-t-0'] }
            },
            bodyrow: { class: ['hover:text-primary-500 hover:bg-neutral-600 !h-[50px]'] },
            bodycell: { class: ['!py-0'] },
            footer: { class: ['!p-0 !font-normal'] }
        }"
        @sort="onSort($event)"
        @column-resize-end="endColumnResize"
        @mousedown="trackMouseDown"
    >
        <template #header>
            <TagsListHeader
                :search-string="searchQuery"
                :selected-tags="selectedTags"
                :brand-id="brandId"
                :avg-score-filter-payload="avgScoreFilter"
                @search="onSearch"
                @deselect-all-tags="deselectAllTags"
                @tag-created="loadLazyData"
                @tags-deleted-success="deleteTagsSuccess"
                @update-avg-score-filter="changeScoreFilter"
                @remove-filter="removeFilter"
                @clear-all-filters="removeAllFilters"
            />
        </template>

        <Column
            selection-mode="multiple"
            :pt="{
                input: { class: ['h-4 w-4'] },
                bodycell: { class: ['w-8 max-w-8 !py-0'] },
                headerCell: { class: ['w-8 max-w-10'] },
                pcHeaderCheckbox: {
                    root: { class: ['!h-4 !w-4 !py-0 !align-middle'] },
                    box: { class: ['!h-4 !w-4 !rounded'] }
                },
                pcRowCheckbox: {
                    root: { class: ['!h-4 !w-4 !py-0 !align-middle'] },
                    box: { class: ['!h-4 !w-4 !rounded'] }
                }
            }"
        >
        </Column>
        <Column
            v-tooltip.bottom="'Tag name'"
            field="name"
            type="text"
            header="Tag name"
            :sortable="!resizing && !loading"
            sort-field="name"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-56 min-w-56 max-w-56 font-bold tag-cell truncate'] }
            }"
            :pt-options="{ mergeProps: false }"
        >
            <template #body="{ data }">
                <TagNameCell :tag-summary="data" :loading="loading" @tag-name-updated="onTagNameUpdated" />
            </template>
        </Column>
        <Column
            field="count"
            header="Brand assets"
            class="w-2"
            :sortable="!resizing && !loading"
            sort-field="count"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] }
            }"
        >
            <template #body="{ data }">
                <Tag severity="secondary" :value="data.count || '-'" :pt="{ root: { class: ['font-normal'] } }" />
            </template>
        </Column>
        <Column
            field="averageScore"
            :sortable="!resizing && !loading"
            sort-field="averageScore"
            header="Average brand score"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-14'] }
            }"
        >
            <template #body="{ data }">
                <ScoreDisplay v-if="data.averageScore !== null && data.count > 0" :score="data.averageScore">
                </ScoreDisplay>
                <span v-else>-</span>
            </template>
        </Column>
        <Column
            field="lastAssignedAt"
            :sortable="!resizing && !loading"
            sort-field="lastAssignedAt"
            header="Last applied on"
            data-type="date"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] }
            }"
        >
            <template #body="{ data }">
                <span v-if="data.tag.lastAssignedAt">{{ dayjs(data.tag.lastAssignedAt).format('ll') }}</span>
                <span v-else>-</span>
            </template>
        </Column>
        <Column
            field="createdOn"
            :sortable="!resizing && !loading"
            sort-field="createdAt"
            header="Created on"
            data-type="date"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] }
            }"
        >
            <template #body="{ data }">
                <span v-if="data.tag.createdAt">{{ dayjs(data.tag.createdAt).format('ll') }}</span>
                <span v-else>-</span>
            </template>
        </Column>
        <Column
            field="createdBy"
            header="Created by"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-12 min-w-24'] }
            }"
        >
            <template #body="{ data }">
                <span v-if="data.tag.createdBy" v-tooltip.top="data.tag.createdBy.email">
                    {{ data.tag.createdBy.username }}
                </span>
                <span v-else>-</span>
            </template>
        </Column>

        <Column
            field="otherBrandCount"
            header="Other brands' assets"
            :pt="{
                ...columnPt,
                headerCell: { class: ['transition-colors', 'duration-300'] },
                bodyCell: { class: [...columnPt.bodyCell.class, 'w-2 min-w-24'] }
            }"
            :sortable="!resizing && !loading"
            sort-field="otherBrandCount"
        >
            <template #body="{ data }">
                <span class="font-normal">{{ data.otherBrandCount || '-' }}</span>
            </template>
        </Column>
        <template #footer>
            <Paginator
                v-model:first="tableState.first"
                :rows="tableState.size"
                :total-records="pageInfo?.totalCount"
                :pt="{
                    root: { class: ['!py-1'] },
                    pcJumpToPageDropdown: {
                        class: ['h-8'],
                        label: { class: ['!p-1.5'] },
                        dropdown: { class: ['w-8'] }
                    },
                    first: { class: ['h-8 !m-0 transition duration-300 ease-in-out hover:bg-primary-500/[.12] hover:shadow-lg '] },
                    prev: { class: ['h-8 !m-0 transition duration-300 ease-in-out hover:bg-primary-500/[.12] hover:shadow-lg '] },
                    next: { class: ['h-8 !m-0 transition duration-300 ease-in-out hover:bg-primary-500/[.12] hover:shadow-lg '] },
                    last: { class: ['h-8 !m-0 transition duration-300 ease-in-out hover:bg-primary-500/[.12] hover:shadow-lg '] }
                }"
                template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageDropdown"
                current-page-report-template="Showing {first} to {last} of {totalRecords}"
                @page="onPage"
            >
                <template #jumptopagedropdownicon>
                    <FaIcon icon="fas fa-caret-down"></FaIcon>
                </template>
                <!-- <template #start><div class="min-w-20"></div></template>
<template #end="slotProps">
                    <div class="min-w-20">
                        {{ slotProps.state.first + 1 }} -
                        {{ Math.min(slotProps.state.first + slotProps.state.rows, pageInfo.totalCount) }} of
                        {{ pageInfo.totalCount }}
                    </div>
                </template> -->
            </Paginator>
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type DataTableSortEvent } from 'primevue/datatable'
import { type PageState } from 'primevue/paginator'
import { onMounted, inject, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useTagsStore } from '@/stores/tagsStore'
import ScoreDisplay from '@/components/ScoreDisplay.vue'
import TagsListHeader from './TagsListHeader.vue'
import TagNameCell from './TagNameCell.vue'
import { dayjsPlugin } from '@/plugins/dayjs'
import {
    type TagSummary,
    type ColumnNameMap,
    type TagFilterApiParams,
    type ContentTag,
    TagsListOrderByOptions
} from '@/interfaces/Tag'
import { useToast } from 'primevue/usetoast'
import { useRouter, useRoute } from 'vue-router'
import type { TableState } from '@/interfaces/Table'
import { validateAndComputeOrderBy } from '@/utils/helpers'

const dayjs: typeof dayjsPlugin = inject('dayjs') || dayjsPlugin

const router = useRouter()
const toast = useToast()
const route = useRoute()

const brandId = route.params.brandId as string

const loading = ref(false)

const tagsStore = useTagsStore()
const { tagSummaries, pageInfo, avgScoreFilter } = storeToRefs(tagsStore)

const columnPt = {
    headerTitle: { class: ['truncate whitespace-nowrap font-normal'] },
    bodyCell: { class: ['whitespace-nowrap h-12 !py-0'] }
}

const tableState = ref<TableState<TagsListOrderByOptions>>({
    page: 1,
    size: 50,
    orderBy: TagsListOrderByOptions.LastAssignedAtAsc,
    sortOrder: -1,
    first: 0
})

const resizing = ref(false)

const selectedTags = ref<TagSummary[]>([])

const columnNameMap: ColumnNameMap = {
    name: 'name',
    count: 'brandAssets',
    averageScore: 'avgBrandScore',
    lastAssignedAt: 'lastAppliedOn',
    createdAt: 'createdOn',
    createdBy: 'createdBy'
}


onMounted(async () => {
    // TODO: encoding and decoding URL should live in a composable
    const query = { ...route.query }
    decodeQueryToFilterState(query)
    decodeQueryToTableState(query)
    updateUrlQueryParams(constructTagsListQuery())
    loadLazyData()
})

function onTagNameUpdated(updatedTag: ContentTag) {
    // Check if the new name matches the search query
    if (!updatedTag.name.includes(searchQuery.value)) {
        loadLazyData() // Refetch to refresh the tag list based on current search
    }
}

function decodeQueryToTableState(query: any) {
    const { page, orderBy, sortOrder } = query
    tableState.value.page = validatePage(page)
    tableState.value.orderBy = validateOrderBy(orderBy)
    tableState.value.sortOrder = validateSortOrder(sortOrder)
    tableState.value.first = (tableState.value.page - 1) * tableState.value.size
}

function decodeQueryToFilterState(query: any) {
    const { minScore, maxScore, isCustomScoreRange, nameContains } = query

    if (minScore || maxScore) {
        avgScoreFilter.value = {
            type: 'avgScore',
            minScore: validateMinAvgScore(minScore),
            maxScore: validateMaxAvgScore(maxScore),
            isCustom: validateAvgScoreIsCustom(isCustomScoreRange)
        }
    }

    if (nameContains) {
        searchQuery.value = nameContains // TODO: validation on search string
    }
}

function constructTagsListQuery() {
    // Update query params if they were invalid and sets them to defaults
    const updatedQueryParams: any = {
        orderBy: columnNameMap[tableState.value.orderBy as keyof ColumnNameMap],
        sortOrder: tableState.value.sortOrder === -1 ? 'dsc' : 'asc',
        page: tableState.value.page
    }

    if (avgScoreFilter.value && avgScoreFilter.value.minScore !== null && avgScoreFilter.value.maxScore !== null) {
        updatedQueryParams.minScore = avgScoreFilter.value.minScore
        updatedQueryParams.maxScore = avgScoreFilter.value.maxScore
    }

    return updatedQueryParams
}

function validateAvgScoreIsCustom(isCustom: string) {
    return isCustom === 'false' ? false : true
}

function validateMinAvgScore(minScore: any) {
    const minAvgScore = parseInt(minScore)
    return isNaN(minAvgScore) || minAvgScore < 0 ? 0 : minAvgScore
}

function validateMaxAvgScore(maxScore: any) {
    const maxAvgScore = parseInt(maxScore)
    return isNaN(maxAvgScore) || maxAvgScore > 100 ? 100 : maxAvgScore
}

function validatePage(page: any) {
    const pageNumber = parseInt(page)
    return isNaN(pageNumber) || pageNumber < 1 ? tableState.value.page : pageNumber
}

function validateOrderBy(orderBy: any) {
    return Object.values(columnNameMap).includes(orderBy) ? getColumnKeyByValue(orderBy) as TagsListOrderByOptions: tableState.value.orderBy as TagsListOrderByOptions
}

function validateSortOrder(sortOrder: any) {
    return sortOrder === 'asc' || sortOrder === 'dsc' ? (sortOrder === 'dsc' ? -1 : 1) : tableState.value.sortOrder
}

function getColumnKeyByValue(value: string) {
    return Object.keys(columnNameMap).find((key) => columnNameMap[key as keyof ColumnNameMap] === value)
}


function updateUrlQueryParams(params: any, { queryReplace = false, routerReplace = false } = {}) {
    if (routerReplace) {
        router.replace({ query: queryReplace ? { ...params } : { ...route.query, ...params } })
    } else {
        router.push({ query: queryReplace ? { ...params } : { ...route.query, ...params } })
    }
}

const searchQuery = ref('')
function onSearch(s: string) {
    searchQuery.value = s
    if (s.length > 0) {
        updateUrlQueryParams({
            nameContains: s
        })
    } else {
        const query = { ...route.query }
        delete query.nameContains
        updateUrlQueryParams(query, { queryReplace: true })
    }
    loadLazyData()
}

function changeScoreFilter(val: any) {
    avgScoreFilter.value = {
        type: 'avgScore',
        minScore: val.minScore,
        maxScore: val.maxScore,
        isCustom: val.isCustom
    }
    updateUrlQueryParams({
        minScore: val.minScore,
        maxScore: val.maxScore,
        isCustomScoreRange: val.isCustom
    })
    loadLazyData()
}

function constructTableApiParams(): TagFilterApiParams {
    const { page, size, orderBy, sortOrder } = tableState.value
    const computedOrderBy = validateAndComputeOrderBy(orderBy, sortOrder, TagsListOrderByOptions)

    const params: TagFilterApiParams = {
        page: page,
        size,
        orderBy: computedOrderBy as TagsListOrderByOptions
    }

    if (searchQuery.value) {
        params.nameContains = searchQuery.value
    }

    if (avgScoreFilter.value) {
        params.minScore = avgScoreFilter.value.minScore
        params.maxScore = avgScoreFilter.value.maxScore
    }

    return params
}

function removeFilter(filterType: string): void {
    if (filterType == 'avgScore') {
        tagsStore.clearScoreFilter()
    }
    const query = { ...route.query }
    delete query.minScore
    delete query.maxScore
    delete query.isCustomScoreRange
    router.push({ query: { ...query } })
    loadLazyData()
}

const removeAllFilters = (): void => {
    tagsStore.clearAllFilters()
    const query = { ...route.query }
    delete query.minScore
    delete query.maxScore
    delete query.isCustomScoreRange
    router.push({ query: { ...query } })
    loadLazyData()
}

const loadLazyData = async () => {
    loading.value = true
    const params = constructTableApiParams()
    try {
        await tagsStore.fetchTags(brandId, params)
    } catch (error) {
        console.error('Failed to fetch tags.', error)
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch tags.`,
            life: 3000
        })
    } finally {
        loading.value = false
    }
}

const onPage = async (event: PageState) => {
    tableState.value.page = event.page + 1
    tableState.value.size = event.rows
    updateUrlQueryParams({ page: tableState.value.page })
    loadLazyData()
}
const onSort = async (event: DataTableSortEvent) => {
    deselectAllTags()
    tableState.value.orderBy = event.sortField ? event.sortField.toString() as TagsListOrderByOptions : undefined
    tableState.value.sortOrder = event.sortOrder !== null ? event.sortOrder : undefined
    tableState.value.page = 1
    tableState.value.first = 0
    updateUrlQueryParams({
        orderBy: columnNameMap[tableState.value.orderBy as keyof ColumnNameMap],
        sortOrder: tableState.value.sortOrder === -1 ? 'dsc' : 'asc',
        page: tableState.value.page
    })
    loadLazyData()
}

const trackMouseDown = (event: MouseEvent): void => {
    if (event?.target) {
        const target = event.target as HTMLElement
        if (target.getAttribute('data-pc-section') === 'columnresizer') {
            resizing.value = true
        }
    }
}

const rowClass = (data: any): string => {
    return selectedTags.value.includes(data) ? 'border-b-2 border-red-500' : ''
}

const deselectAllTags = (): void => {
    selectedTags.value = []
}

const deleteTagsSuccess = () => {
    deselectAllTags()
    loadLazyData()
}

const endColumnResize = (): void => {
    setTimeout(() => {
        resizing.value = false
    }, 300)
}
</script>

<style lang="scss">
.tags-list-table tr[data-pc-section='bodyrow'] {
    #tag-name-cell .tag-controls {
        display: none;
    }

    &:hover {
        .tag-cell {
            color: rgb(var(--primary-500));
        }

        #tag-name-cell .tag-controls {
            display: flex;
        }
    }

    &[data-p-highlight='true'] {
        background-color: rgba(var(--primary-500) / 0.12);
        td {
            border-bottom: 1px solid rgba(var(--surface-950) / 0.1);
        }
    }
}
.tags-table-root {
    height: calc(100vh - 40px);
}
</style>
